<template>
  <v-container>
    <v-layout align-space-between>
      <v-flex xs5>
        <v-flex>
          <h2
            v-if="!editingTitle"
            class="margin-b-4 border-b-none"
            style="display: flex; align-items: center"
          >
            {{ currentTicket.title }}
            <span
              style="max-height: 24px; margin: 0 0 1px 4px"
              @click="editTitle"
            >
              <CRIcon
                v-if="!editingTitle"
                :id="`tickets-quick-view-button-edit-title`"
                color="primary"
                view-box="0 0 24 24"
              >
                edit
              </CRIcon>
            </span>
          </h2>
          <v-layout>
            <v-flex xs11>
              <v-text-field
                v-if="editingTitle"
                :id="`tickets-quick-view-text-field-edit-title`"
                v-model="currentTicket.title"
                flat
                solo
              />
            </v-flex>
            <v-flex xs1>
              <span @click="saveTitle">
                <CRIcon
                  v-if="editingTitle"
                  :id="`tickets-quick-view-icon-edit-title`"
                  color="primary"
                  view-box="0 0 24 24"
                  style="margin: 8px 0 0 5px"
                >
                  save
                </CRIcon>
              </span>
            </v-flex>
          </v-layout>

          <v-layout
            align-space-between
            column
            class="background-gray-blue padding-x-3 padding-y-2 margin-b-3 border-radius-regular"
          >
            <template v-if="loading">
              <v-flex
                v-for="index in 1"
                :key="`creator-detail-row-${index}--loading`"
                py-1
              >
                <v-progress-linear color="gray" height="3" indeterminate />
              </v-flex>
            </template>
            <template v-else>
              <v-flex
                v-for="item in ticketCreatorDetail"
                :key="`creator-detail-row-${item.label}`"
                py-1
              >
                {{ item.label }}: {{ item.value }}
              </v-flex>
              <div v-if="props.item.ticketType === 'Complaint'">
                <div v-if="row.operatorName">
                  {{ `Provider: ${row.operatorName}` }}
                </div>
                <div
                  v-for="classification in props.item
                    .complaintClassificationIds"
                  :key="`complaint-type-row-${classification.label}`"
                  py-1
                >
                  {{ classification.label }}
                </div>
              </div>
              <div v-if="props.item.ticketType === 'Cancellation'">
                {{ cancellationReason }}
              </div>
            </template>
          </v-layout>

          <AutoCompleteUser
            :id="`tickets-quick-view`"
            label="Assignee"
            :initial-user="op(currentTicket, 'assignedToId')"
            are-ticket-users
            @user-selected="userSelected"
          />

          <label>Severity</label>
          <v-select
            :id="`tickets-quick-view-select-ticket-severity`"
            :disabled="disabled"
            :items="severity"
            :value="selectModels.severity"
            flat
            item-text="label"
            item-value="id"
            return-object
            append-icon="keyboard_arrow_down"
            solo
            @input="selectSeverity($event)"
          >
            <template #selection="{ item }">
              <CRIcon
                v-if="item.id === 1"
                :width="16"
                :height="16"
                color="lightGray"
              >
                extreme
              </CRIcon>
              <CRIcon
                v-if="item.id === 2"
                :width="16"
                :height="16"
                color="lightGray"
              >
                high
              </CRIcon>
              <CRIcon
                v-if="item.id === 3"
                :width="16"
                :height="16"
                color="lightGray"
              >
                medium
              </CRIcon>
              <CRIcon
                v-if="item.id === 4"
                :width="16"
                :height="16"
                color="lightGray"
              >
                low
              </CRIcon>
              <span style="padding-left: 12px">{{ item.label }}</span>
            </template>
          </v-select>
        </v-flex>

        <div v-if="isOperatorCancellationTicket && !isTicketClosed">
          <v-layout align-center justify-center>
            <v-btn
              :id="`tickets-quick-view-button-deny-ticket`"
              :disabled="loading"
              :loading="loading"
              color="primary"
              outline
              large
              @click="denyOperatorRequest"
            >
              Deny Request
            </v-btn>
            <v-btn
              :id="`tickets-quick-view-button-cancel-ticket`"
              :disabled="loading"
              :loading="loading"
              class="btn-primaryaction"
              large
              @click="cancelOperatorRequest"
            >
              Cancel Referral
            </v-btn>
          </v-layout>
        </div>

        <v-layout
          v-else-if="
            isOperatorCancellationTicket &&
            isTicketClosed &&
            isOperatorCancellationTicketDenied
          "
          align-center
          justify-center
        >
          <h3 style="font-size: 16px">Cancellation Request Denied</h3>
        </v-layout>
        <v-row
          v-else-if="
            isOperatorCancellationTicket &&
            isTicketClosed &&
            isOperatorCancellationTicketCancelled
          "
          align-center
          justify-center
        >
          <v-layout justify-center>
            <h3 style="font-size: 16px; margin-bottom: 10px">
              Referral Cancelled
            </h3>
          </v-layout>
          <v-layout justify-center>
            <h3 style="font-size: 14px">
              Final Penalty: {{ cancellationPenaltyAmount }}
            </h3>
          </v-layout>
        </v-row>
        <v-layout
          v-else-if="!isTicketClosed"
          align-center
          justify-center
          class="margin-t-4"
        >
          <div v-if="currentTicket.ticketTypeId === 1">
            <v-btn
              :id="`tickets-quick-view-button-close-and-void-ticket`"
              :disabled="loading"
              :loading="loading"
              color="primary"
              large
              outline
              @click="closeVoid"
            >
              Void Cancel
            </v-btn>
            <v-btn
              v-if="currentTicket.ticketTypeId === 1"
              :id="`tickets-quick-view-button-close-and-cancel-ticket`"
              :disabled="loading"
              :loading="loading"
              class="button-text-color"
              color="red"
              large
              outline
              @click="cancel"
            >
              Cancel Reservation
            </v-btn>
          </div>
          <v-btn
            v-else
            :id="`tickets-quick-view-button-close-ticket`"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            large
            @click="close"
          >
            Close Ticket
          </v-btn>
        </v-layout>
      </v-flex>
      <v-flex offset-xs1 xs6>
        <div class="comments margin-b-6 overflow-auto">
          <h2
            v-if="referral && referral.companyName"
            class="text-primary margin-b-4 border-b-none"
          >
            <span class="text-primary">
              Operator: {{ referral.companyName }}
            </span>
          </h2>
          <div v-if="loading" class="text-xs-center">
            <v-progress-circular color="primary" indeterminate />
          </div>
          <div v-if="!loading">
            <v-layout
              v-for="ticket in subTickets"
              :key="ticket.subTicketId"
              class="margin-t-2"
              row
            >
              <v-flex xs1>
                <v-avatar v-if="false" size="2.5em">
                  <img alt="Avatar" src="https://place-hold.it/100" />
                </v-avatar>
                <CRIcon v-if="true" color="primary" :width="32.5" :height="32">
                  my_account
                </CRIcon>
              </v-flex>
              <v-flex
                class="padding-l-1 margin-l-2"
                style="text-align: left; margin-left: 10px"
                column
                xs11
              >
                <h3 class="margin-b-2 text-primary line-height-16">
                  {{ ticket.creatorFirstName }} {{ ticket.creatorLastName }}
                </h3>
                <div>
                  <pre class="white-space-pre-wrap">{{ ticket.comments }}</pre>
                </div>
                <div class="text-gray-medium-light">
                  {{ createdAtDatetime(ticket.createdAt) }}
                </div>
              </v-flex>
            </v-layout>
          </div>
        </div>
        <div>
          <v-textarea
            :id="`tickets-quick-view-text-area-add-comment`"
            v-model="selectModels.newComment"
            :disabled="disabled"
            auto-grow
            flat
            label="Add a Comment"
            rows="2"
            hide-details
            solo
          />
          <div class="d-flex align-center">
            <div
              v-if="isOperatorPaymentDisputeTicket"
              class="font-weight-bold"
              style="text-align: left"
            >
              Note: All comments added to this ticket will be visible to the
              operator.
            </div>
            <v-spacer />
            <v-btn
              :id="`tickets-quick-view-button-add-comment`"
              :disabled="loading || !selectModels.newComment"
              :loading="loading"
              color="primary"
              style="
                margin-left: 0;
                margin-right: 0;
                -webkit-box-flex: 0 !important;
                flex-grow: 0 !important;
                flex-shrink: 1 !important;
              "
              @click="submit"
            >
              Add
            </v-btn>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import operatorCancel from '@/services/operatorCancel'
import ticket from '@/services/ticket'
import { authComputed } from '@/state/helpers'
import op from 'simple-object-path'
import { DateTime } from 'luxon'
import { filter } from '@/utils/filter'
import AutoCompleteUser from '@/components/AutoCompleteUser.vue'
import { EventBus } from '@/utils/event-bus'
import { currencyFilter } from '@/utils/currency'
import { phoneFormatFilter } from '@/utils/phone'
import { getClassifications } from '@/services/reservations'

const OPERATOR_CANCELLATION_TICKET_TYPE_ID = 12
const REINSTATE_RESERVATION_TICKET_TYPE_ID = 13
const OPERATOR_PAYMENT_DISPUTE_TICKET_TYPE_ID = 14

export default {
  name: 'TicketsQuickView',
  components: {
    AutoCompleteUser,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      disabled: false,
      editingTitle: false,
      editingAssignee: false,
      expanded: this.props.expanded,
      selectModels: {
        severity: {},
        newComment: '',
      },
      currentTicket: {},
      subTickets: [],
      severity: [],
      staffList: [],
      op,
      referral: {},
      penaltyAmount: null,
      minPenaltyAmount: null,
      cancellationClassifications: {},
      cancellationReason: '',
    }
  },
  computed: {
    ...authComputed,
    isTicketClosed() {
      return this.row.status.toLowerCase() === 'closed'
    },
    isOperatorPaymentDisputeTicket() {
      return (
        this.currentTicket?.ticketTypeId ==
        OPERATOR_PAYMENT_DISPUTE_TICKET_TYPE_ID
      )
    },
    isOperatorCancellationTicket() {
      return (
        this.currentTicket?.ticketTypeId == OPERATOR_CANCELLATION_TICKET_TYPE_ID
      )
    },
    isOperatorCancellationTicketDenied() {
      return this.referral?.operatorCancellationStatusKey === 'denied'
    },
    isOperatorCancellationTicketCancelled() {
      return this.referral?.operatorCancellationStatusKey === 'cancelled'
    },
    cancellationPenaltyAmount() {
      return currencyFilter(this.referral?.operatorCancellationPenalty)
    },
    ticketCreatorDetail() {
      const creatorType = this.currentTicket?.isCreatorTripContact
        ? 'Trip Contract'
        : 'Main'
      const details = [
        {
          label: 'Creator',
          value: `${this.currentTicket?.creatorFirstName} ${this.currentTicket?.creatorLastName} (${creatorType})`,
        },
      ]
      if (this.currentTicket?.creatorEmail) {
        details.push({
          label: 'Email',
          value: this.currentTicket.creatorEmail,
        })
      }
      if (this.currentTicket?.creatorPhoneNumber) {
        details.push({
          label: 'Phone',
          value: phoneFormatFilter(this.currentTicket.creatorPhoneNumber),
        })
      }
      return details
    },
  },
  async mounted() {
    let cancellationTypeFuture = null
    if (this.props.item.ticketType === 'Cancellation')
      await this.getCancellationClassification()
    this.loading = true
    const severityTypeData = await this.$store.dispatch('types/getTicketLevels')
    this.severity = severityTypeData.data
    const adminFilterObject = {
      column: {
        _t_id: 'admin_data_search_id',
        prop: 'group.groupId',
        filterType: 'eq',
      },
      value: 1,
    }
    const userFilterObject = {
      column: {
        _t_id: 'user_data_search_id',
        prop: 'group.groupId',
        filterType: 'eq',
      },
      value: 2,
    }
    const staffFilter = filter()
    const parentFilter = staffFilter.createParent('or')
    staffFilter.add(parentFilter, adminFilterObject)
    staffFilter.add(parentFilter, userFilterObject)
    const staffData = await this.$store.dispatch('users/getUsers', {
      page: 1,
      pageSize: -1,
      filters: staffFilter.asQueryParams(),
    })
    this.staffList = staffData?.data?.resultList

    await this.getTicket()
    if (this.isOperatorCancellationTicket) {
      await this.getReservation()
      await this.getOperatorCancellationPenaltyInfo()
    }

    if (cancellationTypeFuture) await cancellationTypeFuture

    this.loading = false
    this.scrollCommentsToBottom()

    EventBus.$on('refresh-tickets', () => {
      this.props.expanded = false
      this.$emit('refresh-query-request')
    })
  },
  methods: {
    createdAtDatetime(datetime) {
      return DateTime.fromISO(datetime).toFormat('MM/dd/yy · hh:mm a')
    },
    async getTicket() {
      const ticketData = await ticket.byId(this.row.ticketId)
      this.currentTicket = ticketData.data

      this.selectModels.severity = this.severity.find((level) => {
        return (
          level.id.toString() ===
          this.currentTicket.ticketSeverityTypeId.toString()
        )
      })
      Object.assign(this.subTickets, this.currentTicket.subTickets)
      this.subTickets.unshift({ ...ticketData.data, subTicketId: 0 })
      this.expandSubTickets()
    },
    async getReservation() {
      let { reservationId } = this.row

      const response = await this.$store.dispatch(
        'reservations/reservationById',
        reservationId
      )

      this.referral = response?.data?.referredTo.find(
        (referral) => referral.reservationId === this.row.referralId
      )
    },
    async getOperatorCancellationPenaltyInfo() {
      let { reservationId } = this.row

      const response = await operatorCancel.getOperatorCancellationPenaltyInfo(
        reservationId
      )

      this.penaltyAmount = response?.data?.cancellationInfoDTO?.totalPenalty
      this.minPenaltyAmount = this.amount
    },
    expandSubTickets() {
      this.subTickets.forEach((ticket, index) => {
        const creatingUser = this.staffList.find((person) => {
          return person.userId.toString() === ticket.createdById.toString()
        })
        this.subTickets.splice(index, 1, { ...ticket, ...creatingUser })
      })
    },
    scrollCommentsToBottom() {
      setTimeout(() => {
        const commentBox = this.$el.querySelector('.comments')
        commentBox.scrollTop = commentBox.scrollHeight
      }, 1000)
    },
    selectSeverity(severity) {
      this.update({
        ticketSeverityTypeId: severity.id,
      })
    },
    async update(payload) {
      this.loading = true

      const ticketUpdated = await ticket
        .partialUpdate({
          id: this.currentTicket.ticketId,
          payload,
        })
        .catch((error) => {
          this.loading = false
          this.displayWarning = true
          this.warningMessage = error

          return false
        })
      this.$store.dispatch(
        'app/showAlert',
        { message: 'Ticket updated.' },
        { root: true }
      )
      if (ticketUpdated) {
        this.$emit('refresh-query-request')
        this.loading = false
        this.props.expanded = false
      }
    },
    async close() {
      const newComment = {
        comments: 'Closing Ticket',
        createdAt: new Date().toISOString(),
        createdById: this.currentUser.userId,
      }
      this.update({
        ticketStatusTypeId: 3,
        subTickets: [newComment],
      })
      this.$store.dispatch(
        'app/showAlert',
        { message: 'Ticket updated.' },
        { root: true }
      )
    },
    async closeVoid() {
      await this.update({
        ticketStatusTypeId: 3,
        voidCancellation: true,
      })
      EventBus.$emit('refresh-detail')
    },
    async cancel() {
      const component = () => import('@/components/CancellationSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          ticketId: this.currentTicket.ticketId,
          reservationId: this.row.reservationId,
          title: 'Finalize Cancellation',
        },
        component,
      })
      EventBus.$emit('refresh-detail')
    },
    async denyOperatorRequest() {
      if (this.referral) {
        const component = () => import('@/components/DenyRequestSidebar.vue')
        this.$store.dispatch('app/openSidebarDialog', {
          data: {
            ticketId: this.currentTicket.ticketId,
            referral: this.referral,
            title: 'Deny Request',
          },
          component,
        })
      }
    },
    async cancelOperatorRequest() {
      if (this.referral) {
        const newComment = {
          comments: 'Closing Ticket and Confirming Cancellation Request',
          createdAt: new Date().toISOString(),
          createdById: this.currentUser.userId,
        }

        const component = () =>
          import('@/components/UnassignReferralSidebar.vue')
        this.$store.dispatch('app/openSidebarDialog', {
          data: {
            ticketId: this.currentTicket.ticketId,
            newTicketComment: newComment,
            reservation: { managedId: this?.referral?.managedId },
            referral: this.referral,
            title: 'Unassign Referral',
          },
          component,
        })
      }
    },
    submit() {
      const newComment = {
        comments: this.selectModels.newComment,
        createdAt: new Date().toISOString(),
        createdById: this.currentUser.userId,
      }
      this.update({
        sendEmail: !this.isOperatorCancellationTicket,
        subTickets: [newComment],
      })
    },
    editTitle() {
      this.editingTitle = true
    },
    async saveTitle() {
      await this.update({
        title: this.currentTicket.title,
      })
      this.editingTitle = false
    },
    userSelected(staffObject) {
      this.update({
        assignedToId: staffObject.userId,
      })
    },
    async getCancellationClassification() {
      const response = await getClassifications('cancellation')
      const complaintClassifications = response.data.data
      for (let classification of complaintClassifications) {
        this.addCancellationClassificationsAndChildren(classification)
      }
      this.constructCancellationReason(this.props.item?.classificationId)
    },
    addCancellationClassificationsAndChildren(classification) {
      if (classification == null) {
        return
      }

      this.cancellationClassifications[
        classification.classificationId
      ] = classification

      if (classification.childClassifications == null) {
        return
      }

      for (let child of classification.childClassifications) {
        this.addCancellationClassificationsAndChildren(child)
      }
    },
    constructCancellationReason(classificationId, limit = 2) {
      let index = 0
      let cancellationReasons = []
      let classification = this.cancellationClassifications?.[classificationId]
      while (classification != null && index < limit) {
        cancellationReasons.unshift(classification.label)
        classification = this.cancellationClassifications?.[
          classification.parentClassificationId
        ]
        index += 1
      }
      this.cancellationReason = cancellationReasons.join(' - ')
    },
  },
}
</script>

<style lang="scss" scoped>
div.comments {
  height: 250px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($black-base, 0.5);
  border-radius: 4px;
  -webkit-box-shadow: 0 0 1px rgba($white, 0.5);
}

::v-deep.v-input__slot {
  background: $white;
}

.v-datatable__expand-col {
  background-color: $blue-light;
}
</style>
