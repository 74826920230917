<template>
  <BaseTableWrapper
    :special-text-formatting="specialTextFormatting"
    :table-init="tableInit"
    :is-admin="isAdmin"
    :columns="columns"
    :initial-filters="initialFilters"
  >
    <h1 v-if="!hideDetails" class="page-header">Tickets</h1>
    <div v-else></div>
  </BaseTableWrapper>
</template>

<script>
import ticket from '@/services/ticket'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { friendlyDate } from '@/utils/time'
import TicketsQuickView from '@/components/TicketsQuickView.vue'
import GenericActionableColumn from '@/components/GenericActionableColumn.vue'
import QuotesActionableColumn from '@/components/QuotesActionableColumn.vue'
import { authComputed } from '@/state/helpers'
import { DateTime } from 'luxon'
import {
  userPredefined,
  calculatedValues,
  datePredefined,
  noFutureDatesPredefined,
} from '@/utils/predefined'
import { deepClone } from '@/utils/deepClone'

const ticketTypeMap = [
  {
    text: 'Quote',
    value: '1',
    filterType: 'isnotnull',
  },
  {
    text: 'Reservation',
    value: '0',
    filterType: 'isnotnull',
    overrideProp: 'reservationId',
  },
]

let categories = [
  {
    _t_id: '1711fa30',
    text: 'Ticket Type',
    prop: 'quoteId',
    values: deepClone(ticketTypeMap),
    type: 'text',
    method: 'or',
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Tickets',
    }
  },
  props: {
    ticketIdFilter: {
      type: [Number, String],
      default: null,
    },
    reservationIdFilter: {
      type: [Number, String],
      default: null,
    },
    managedId: {
      type: [Number, String],
      default: null,
    },
    hideReservationIds: {
      type: Boolean,
    },
    hideDetails: {
      type: Boolean,
    },
    reasons: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      initialFilters: [],
      isAdmin: false,
      specialTextFormatting: {
        createdAt: friendlyDate,
        closedAt: friendlyDate,
      },
      columns: [],
      tableInit: {
        enableSavedViews: !this.hideDetails,
        enableStatusFilterToggle: false,
        enableColumnConfig: true,
        categories,
        detail: TicketsQuickView,
        detailKeyId: 'ticketId',
        tableId:
          this.$route.name !== 'reservation-detail' ? 'tickets_tv_view' : null,
        addNewEnabled: true,
        addNewHandler: () => this.addNew(),
        currentPage: 1,
        perPage: 10,
        calculatedValues,
        isDetailed: true,
        columns: [],
        collection: 'Tickets',
        action: (params) => this.getQuickViewData(params),
      },
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const user = this.currentUser
    this.isAdmin = user?.group?.key === 'admin'
    await this.getColumns()
    if (this.ticketIdFilter) {
      const ticketFilter = {
        column: this.columns.find(
          (column) => column._t_id === 'ticket_id_filter'
        ),
        value: this.ticketIdFilter,
        hideOnFilterBar: true,
      }

      this.initialFilters = [ticketFilter]
    }
    if (this.reservationIdFilter) {
      const resFilter = {
        column: {
          prop: 'reservationId',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          sortProp: 'reservationId',
        },
        value: this.reservationIdFilter,
        hideOnFilterBar: true,
      }

      this.initialFilters = [...this.initialFilters, resFilter]
    }
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    async getColumns() {
      const columnsOut = [
        {
          _t_id: '9a4b0d9e',
          prop: 'ticketId',
          text: 'Ticket #',
          sort: true,
          filter: !this.hideDetails,
          type: 'number',
          sortProp: 'ticketId',
          filterType: 'eq',
          defaultSort: true,
        },
        {
          _t_id: 'ac4ce1b6',
          prop: 'ticketType',
          text: 'Type',
          sort: true,
          filter: !this.hideDetails,
          type: 'text',
          filterType: 'contains',
          sortProp: 'ticketType',
        },
        {
          _t_id: '127fd3a3',
          prop: 'quoteId',
          text: 'Quote',
          component: QuotesActionableColumn,
          sort: true,
          filter: !this.hideDetails,
          detail: false,
          type: 'number',
          displayType: 'clickable-link-new',
          filterType: 'eq',
          filterProp: 'quote/quoteId',
          sortProp: 'quote/quoteId',
        },
      ]

      if (!this.hideReservationIds) {
        columnsOut.push({
          _t_id: '127fd3a4',
          prop: 'managedId',
          text: 'Reservation',
          component: GenericActionableColumn,
          displayType: 'clickable-reservation-broker',
          action: 'NOOP',
          sort: true,
          filter: !this.hideDetails,
          type: 'text',
          filterType: 'contains',
          sortProp: 'reservation/managedId',
          filterProp: 'reservation/managedId',
        })
      }

      columnsOut.push(
        {
          _t_id: 'c41fe050',
          prop: 'quotePickupDate',
          text: 'Quote Pickup Date',
          computedText: (item, row) => {
            return item
              ? DateTime.fromISO(item, {
                  zone: row.firstStopAddressTimeZone || 'local',
                }).toFormat('MM/dd/yyyy')
              : ''
          },
          sort: true,
          sortProp: 'quotePickupDate',
          filter: !this.hideDetails,
          filterProp: 'quotePickupDate',
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: 'd97e55d2-5b59-47a0-8d0f-00b29122a186',
          prop: 'reservationPickupDate',
          text: 'Reservation Pickup Date',
          computedText: (item, row) => {
            return item
              ? DateTime.fromISO(item, {
                  zone: row.firstStopAddressTimeZone || 'local',
                }).toFormat('MM/dd/yyyy')
              : ''
          },
          sort: true,
          sortProp: 'reservationPickupDate',
          filter: !this.hideDetails,
          filterProp: 'reservationPickupDate',
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: 'c40de050',
          prop: 'status',
          text: 'Status',
          sort: true,
          filter: !this.hideDetails,
          type: 'text',
          filterType: 'eq',
          sortProp: 'status',
        },
        {
          _t_id: '3ba33e65',
          prop: ['assigneeFirstName', 'assigneeLastName'],
          text: 'Assignee',
          sort: true,
          filter: !this.hideDetails,
          type: 'text',
          sortProp: 'assigneeLastName',
          filterType: 'contains',
          predefined: userPredefined,
        },
        {
          _t_id: '3c9d04ae',
          prop: ['creatorFirstName', 'creatorLastName'],
          text: 'Creator',
          sort: true,
          filter: !this.hideDetails,
          type: 'text',
          sortProp: 'creatorLastName',
          filterType: 'contains',
          predefined: userPredefined,
        },
        {
          _t_id: '0b594004',
          prop: 'createdAt',
          text: 'Created Date',
          sort: true,
          filter: !this.hideDetails,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          sortProp: 'createdAt',
        },
        {
          _t_id: '14838418-9b4b-495e-92ab-8f768911a5d0',
          prop: 'closedAt',
          text: 'Closed Date',
          sort: true,
          filter: !this.hideDetails,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(noFutureDatesPredefined),
          sortProp: 'closedAt',
        },
        {
          _t_id: 'dc7e4286',
          prop: 'severity',
          text: 'Severity',
          component: GenericActionableColumn,
          displayType: 'severity',
          sort: true,
          filter: !this.hideDetails,
          type: 'text',
          filterType: 'eq',
          sortProp: 'severity',
        },
        {
          _t_id: 'ticket_id_filter',
          filterType: 'eq',
          prop: 'ticketId',
          filterPlaceholder: true,
        }
      )

      return (this.columns = columnsOut)
    },
    addNew() {
      const routeDetails = { name: 'tickets.add' }
      if (this.reservationIdFilter) {
        routeDetails.params = { managedId: this.managedId }
      }
      this.$router.push(routeDetails)
    },
    async getQuickViewData(params) {
      const output = await ticket.tableview(params)
      for (let out of output.data.resultList) {
        if (out.complaintClassificationIds == null) continue
        if (out.complaintClassificationIds.length == 0) continue
        for (let i = 0; i < out.complaintClassificationIds.length; i++) {
          const classificationId = out.complaintClassificationIds[i]
          let classification = this.reasons?.[`${classificationId}`]
          if (classification == null) continue
          let curr = null
          let label = `${classification.label}`
          if (classification.parentClassificationId != null)
            curr = this.reasons[`${classification.parentClassificationId}`]
          while (curr != null) {
            label = `${curr.label} > ${label}`
            if (curr.parentClassificationId != null) {
              curr = this.reasons[`${classification.parentClassificationId}`]
            } else {
              break
            }
          }
          out.complaintClassificationIds[i] = {
            id: classificationId,
            label: label,
          }
        }
      }
      return output
    },
  },
}
</script>
